<script lang="ts" setup>
import {computed, ref, toRefs, watch} from 'vue';
import {useSession} from '../../core/session';
import {logEvent} from '../../utils';
import {Uint32RawPlatform} from '../../platforms/uint32-raw';
import Icon from '/src/components/layout/Icon.vue';
import {TextLayer} from '/src/core/layers/text.layer';
import {gfxSources} from '/src/draw/fonts/fontTypes';

const props = defineProps<{
    updates: number;
}>();

const session = useSession();
const {platform, layers} = toRefs(session.state);
const templates = computed(() => platform.value && session.platforms[platform.value].getTemplates());
const settings = computed(() => template.value && session.platforms[platform.value].getTemplateSettings());
const fontsList = computed(() => {
    const uniqueFonts = new Set<{name: string; file: Promise<string>}>();
    layers.value
        .filter((layer) => layer.getType() === 'string')
        .forEach((layer) => {
            const font = (layer as TextLayer).font;
            const gfxFontSource = gfxSources.find((f) => f.name === font.name);
            if (gfxFontSource && font.name !== 'adafruit') {
                uniqueFonts.add(gfxFontSource);
            }
        });
    return props.updates ? Array.from(uniqueFonts) : [];
});

const template = ref(session.platforms[platform.value].getTemplate());
watch(template, (val) => {
    if (val) {
        session.platforms[platform.value].setTemplate(val);
        session.virtualScreen.redraw();
    }
});
watch(platform, (val) => {
    if (val) {
        template.value = session.platforms[platform.value].getTemplate();
    }
});

function setSetting(event: Event, name: any) {
    const target = event.target as HTMLInputElement;
    session.platforms[platform.value].getTemplateSettings()[name] = target.checked;
    session.virtualScreen.redraw();
    logEvent('code_setting', name);
}

function changeTemplate() {
    logEvent('code_template', template.value);
}

function downloadFont(font) {
    font.file().then((f) => {
        const blob = new Blob([f], {type: 'text/plain'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${font.name}.h`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    });
}

const LABELS = {
    wrap: 'Wrapper function',
    progmem: 'Declare as PROGMEM',
    include_font: 'Include font declarations',
};
</script>
<template>
    <div
        class="mt-8"
        v-if="platform !== Uint32RawPlatform.id"
    >
        <div
            class="text-lg"
            v-if="Object.keys(templates).length > 1 || Object.keys(settings).length"
        >
            Code settings
        </div>
        <div class="mb-2">
            <div
                class="fui-select label"
                v-if="Object.keys(templates).length > 1"
            >
                <label
                    for="template"
                    class="label-text"
                >
                    Code style:
                </label>
                <select
                    id="template"
                    class="fui-select__select fui-form-input"
                    v-model="template"
                    @change="changeTemplate"
                >
                    <option
                        v-for="(item, idx) in Object.keys(templates)"
                        :key="idx"
                        :value="item"
                    >
                        {{ templates[item].name }}
                    </option>
                </select>
            </div>
            <div
                v-for="(value, key) in settings"
                class="form-control"
            >
                <label class="label cursor-pointer justify-start">
                    <input
                        class="checkbox checkbox-sm checkbox-primary"
                        type="checkbox"
                        :checked="value"
                        @change="setSetting($event, key)"
                    />
                    <span class="label-text ml-2">{{ LABELS[key] ?? key }}</span>
                </label>
            </div>
        </div>
        <template v-if="fontsList.length">
            <div class="text-lg mb-1">Fonts</div>
            <div class="flex flex-col gap-2">
                <div
                    class="flex flex-row gap-1"
                    v-for="font in fontsList"
                    :key="font.name"
                >
                    <Icon
                        type="clip"
                        sm
                        class="text-gray-400"
                    />
                    <div
                        class="text-sm link text-gray-400 truncate"
                        @click="downloadFont(font)"
                    >
                        {{ font.name }}.h
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
