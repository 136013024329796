<script lang="ts" setup>
import {onMounted, onUnmounted, ref, toRefs} from 'vue';
import {logEvent} from '../../utils';
import {useSession} from '/src/core/session';
import router from '/src/router';
import PricingPlans from '../pricing/PricingPlans.vue';

const props = defineProps<{
    canBeClosed?: boolean;
}>();

const session = useSession();
const {state} = session;
const {auth} = toRefs(state);

const emit = defineEmits(['close']);

const currenStep = ref('price');
const email = ref('');
const formStatus = ref('');

function closeModal() {
    emit('close');
}

function nextStep() {
    currenStep.value = 'request';
}

function buyPro() {
    nextStep();
    logEvent('button_buy', 'PRO');
}

function prevStep() {
    currenStep.value = 'price';
    formStatus.value = '';
}

async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json();
}

function submitForm(e) {
    logEvent('button_submit');
    e.preventDefault();
    formStatus.value = 'WAIT';
    postData('https://europe-west4-ancient-medium-282415.cloudfunctions.net/pro-signup', {
        email: email.value,
    }).then((res) => {
        if (res.status) {
            formStatus.value = res.status;
            if (res.status === 'OK') {
                email.value = '';
            }
        } else {
            formStatus.value = 'ERROR';
        }
    });
}

const PRICE = 5.95;

const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
        closeModal();
    }
};

onMounted(() => {
    document.addEventListener('keydown', handleEscapeKey);
    logEvent('show_price', PRICE);
});

onUnmounted(() => {
    document.removeEventListener('keydown', handleEscapeKey);
});
</script>
<template>
    <div
        id="modal_pricing"
        class="modal opacity-100 font-sans"
    >
        <div class="modal-box border border-primary max-w-2xl pointer-events-auto -mt-64">
            <button
                v-if="canBeClosed"
                class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 cursor-pointer"
                @click="closeModal"
            >
                ✕
            </button>
            <div v-if="currenStep === 'price'">
                <h3 class="font-bold text-xl text-center">Upgrade your subscription to unlock all features</h3>
                <PricingPlans />
                <div
                    class="mt-4 text-center"
                    v-if="!auth"
                >
                    Already have an account?
                    <router-link
                        class="bold link"
                        to="/login"
                    >
                        Sign In
                    </router-link>
                </div>
            </div>
            <div
                v-if="currenStep === 'request'"
                class="flex flex-col gap-8"
            >
                <h3 class="font-bold text-xl text-center">Thank you for your interest</h3>
                <template v-if="formStatus !== 'OK'">
                    <div class="text-lg text-center">
                        <p>You can be the first to know once Lopaka Pro is available.</p>
                        <p>Join our Early Adopters program!</p>
                    </div>
                    <form
                        class="input-group text-center relative"
                        @submit="submitForm"
                    >
                        <fieldset :disabled="formStatus === 'WAIT'">
                            <input
                                type="email"
                                class="input input-primary mr-2"
                                placeholder="Email"
                                required
                                v-model="email"
                            />
                            <button
                                class="btn btn-primary uppercase"
                                type="submit"
                            >
                                Submit
                            </button>
                        </fieldset>
                        <span
                            class="loading loading-spinner text-primary absolute top-3"
                            v-if="formStatus === 'WAIT'"
                        ></span>
                    </form>
                    <div
                        v-if="formStatus === 'ERROR'"
                        class="text text-center text-error"
                    >
                        Oops! Something went wrong.
                        <br />
                        Please check if your email address is correct and try again.
                    </div>
                </template>
                <div
                    v-else
                    class="text-lg text-center"
                >
                    You've joined the waiting list.
                    <br />
                    We will inform you as soon as Lopaka PRO becomes available!
                </div>
                <div>
                    <div
                        class="btn btn-sm btn-primary btn-outline uppercase"
                        @click="prevStep"
                    >
                        ← Back
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style></style>
