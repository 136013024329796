import {Project, ProjectScreen} from '/src/api/projects';
import {supabase} from '/src/api/services/supabase';
import {createEmptyImageDataURL} from '/src/utils';

export async function cloneScreen(id: number): Promise<ProjectScreen> {
    const {data, error} = await supabase.rpc('copy_screen', {
        screen_id: id,
    });

    if (error) {
        console.error('Error cloning screen:', error);
        return null;
    }

    return data;
}

export async function deleteScreen(id: number): Promise<void> {
    const {error} = await supabase.from('screens').delete().eq('id', id);

    if (error) {
        console.error('Error deleting screen:', error);
        return;
    }
}

export async function getScreens(project_id): Promise<ProjectScreen[]> {
    const {data, error} = await supabase.from('screens').select('*').filter('project_id', 'eq', project_id);

    if (error) {
        console.error('Error fetching projects:', error);
        return;
    }

    console.log('Screens:', data);
    return data;
}

export async function getPublicScreens(): Promise<ProjectScreen[]> {
    const {data, error} = await supabase.from('screens').select('*');

    if (error) {
        console.error('Error fetching public screens:', error);
        return [];
    }

    console.log('Public Screens:', data);
    return data;
}

export async function createScreen(project: Project, screenTitle): Promise<any> {
    const newScreen = {
        title: screenTitle,
        project_id: project.id,
        img_preview: createEmptyImageDataURL(project.screen_x, project.screen_y),
    };

    const {data, error} = await supabase.rpc('create_screen', newScreen);

    if (error) {
        console.error('Error creating screen:', error);
        return;
    }

    return data;
}
export async function updateScreen(screen: ProjectScreen): Promise<void> {
    const {error} = await supabase.from('screens').update(screen).eq('id', screen.id);

    if (error) {
        console.error('Error updating screen:', error);
        return;
    }
}
