import {createApp} from 'vue';
import * as Sentry from '@sentry/vue';
import '../styles.css';
import AppVue from './components/App.vue';
import router from '/src/router';

const app = createApp(AppVue);

Sentry.init({
    app,
    dsn: 'https://c9fc441bbc0203e764dad927b006d5d8@o4507831755014144.ingest.us.sentry.io/4507831757832192',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/lopaka\.app/],
    // Session Replay
    replaysSessionSampleRate: 0.2, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router).mount('#lopaka_app');
