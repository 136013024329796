<script lang="ts" setup>
import {computed, ref, toRefs} from 'vue';
import {useSession} from '../../core/session';
import {iconsList} from '../../icons/icons';

const session = useSession();
const {customImages} = toRefs(session.state);
const emit = defineEmits(['cleanCustomIcons', 'prepareImages', 'iconClicked']);

function cleanCustom() {
    customImages.value = [];
}

function iconClick(e, item) {
    const data = {
        name: item.name,
        width: item.width,
        height: item.height,
        icon: e.target,
    };
    emit('iconClicked', data);
}

function iconDragStart(e: DragEvent) {
    const target = e.target as HTMLImageElement;
    e.dataTransfer.dropEffect = 'move';
    e.dataTransfer.setData('text/plain', target.dataset.name);
    e.dataTransfer.setData('text/uri', target.src);
}
</script>
<template>
    <div class="fui-icons">
        <details
            open
            v-if="customImages.length"
        >
            <summary class="fui-icons-custom cursor-pointer pb-2">
                Custom
                <div
                    class="tooltip tooltip-bottom"
                    data-tip="Remove all custom images"
                >
                    <span
                        class="fui-icons-remove"
                        @click="cleanCustom"
                    >
                        ×
                    </span>
                </div>
            </summary>
            <div class="fui-iconset flex flex-row flex-wrap">
                <img
                    v-for="(item, index) in customImages"
                    @dragstart="iconDragStart"
                    @click="iconClick($event, item)"
                    draggable="true"
                    :key="index"
                    :src="item.image.src"
                    :data-name="item.name"
                    :width="item.width * 2"
                    :height="item.height * 2"
                    :data-w="item.width"
                    :data-h="item.height"
                    :alt="item.name"
                    :title="item.name"
                />
            </div>
        </details>
        <details
            v-for="(value, key) in iconsList"
            class=""
            open
        >
            <summary class="cursor-pointer pb-2">{{ value.title }}</summary>
            <div class="fui-iconset flex flex-row flex-wrap">
                <img
                    v-for="(item, index) in iconsList[key].icons"
                    @dragstart="iconDragStart"
                    @click="iconClick($event, item)"
                    draggable="true"
                    :key="index"
                    :src="item.image"
                    :data-name="item.name"
                    :data-w="item.width"
                    :data-h="item.height"
                    :width="item.width * 2"
                    :height="item.height * 2"
                    :alt="item.name"
                    :title="item.name"
                    class="object-contain"
                />
            </div>
        </details>
    </div>
</template>
<style lang="css">
.fui-icons {
    overflow-y: auto;
    background: var(--primary-color);
    border: 2px solid var(--border-dark-color);
    border-radius: 0 10px 10px 10px;
    border-top: 0;
    margin: 0 0 8px 0;
    padding: 16px;
    height: 350px;
    color: var(--secondary-color);
}

.fui-iconset img {
    cursor: pointer;
    image-rendering: pixelated;
    display: block;
    margin: 8px;
    border: 1px solid var(--border-color);
    max-width: 128px;
    /* height: auto; */
}

.fui-icons-custom:hover .fui-icons-remove {
    display: inline;
}

.fui-icons-remove {
    display: none;
    cursor: pointer;
    color: var(--danger-color);
    margin: 0 8px;
}
</style>
