import { supabase } from "./services/supabase";

interface User {
    id: number;
    name: string;
    email: string;
}

export const getUsers = async (): Promise<User[]> => {
    const { data, error } = await supabase.from("users").select("*");
    if (error) throw error;
    return data as User[];
};

export const createUser = async (userData: Omit<User, "id">): Promise<User> => {
    const { data, error } = await supabase.from("users").insert(userData)
        .select().single();
    if (error) throw error;
    return data as User;
};

export const getUserSubscription = async (userId: string): Promise<any> => {
    const { data, error } = await supabase
        .from("subscriptions")
        .select("*");
    if (error) {
        console.error("Error getting subs:", error);
        return;
    }
    console.log("getUserSubscription", data);
    return data.length > 0 ? data[0] : null;
};

export const getSubscriptionUpdate = async (id: number): Promise<any> => {
    const { data, error } = await supabase.functions.invoke(
        `subscription_update?id=${id}`,
        {
            method: "GET",
        },
    );
    if (error) {
        console.error("Error getting subscription:", error);
        throw error;
    }
    return data;
};
