<script lang="ts" setup>
import {computed, defineProps} from 'vue';
import AbcIcon from '/src/assets/icons/abc.svg?component';
import CircleIcon from '/src/assets/icons/Circle.svg?component';
import GithubIcon from '/src/assets/icons/github.svg?component';
import GoogleIcon from '/src/assets/icons/google.svg?component';
import LineIcon from '/src/assets/icons/Line.svg?component';
import TextIcon from '/src/assets/icons/Text.svg?component';
import TwitterIcon from '/src/assets/icons/twitter.svg?component';
import ForkIcon from '/src/assets/icons/fork.svg?component';
import InvertIcon from '/src/assets/icons/invert.svg?component';

import {
    CogIcon,
    CursorArrowRaysIcon,
    DocumentDuplicateIcon,
    EllipsisHorizontalIcon,
    GlobeAltIcon,
    PaintBrushIcon,
    PhotoIcon,
    RectangleGroupIcon,
    ShieldExclamationIcon,
    TrashIcon,
    ShareIcon,
    HeartIcon,
    Square3Stack3DIcon,
    ClipboardDocumentIcon,
    PaperClipIcon,
    ArrowUpTrayIcon,
    ArrowsRightLeftIcon,
    ArrowsUpDownIcon,
    ArrowPathIcon,
    ArrowsPointingInIcon,
} from '@heroicons/vue/24/outline';

const props = defineProps<{
    type: string;
    class?: string;
    title?: string;
    sm?: boolean;
    lg?: boolean;
    primary?: boolean;
    danger?: boolean;
    pointer?: boolean;
}>();

const icons = {
    abc: AbcIcon,
    string: TextIcon,
    circle: CircleIcon,
    ellipse: CircleIcon,
    google: GoogleIcon,
    line: LineIcon,
    github: GithubIcon,
    twitter: TwitterIcon,
    fork: ForkIcon,
    clone: DocumentDuplicateIcon,
    cog: CogIcon,
    clipboard: ClipboardDocumentIcon,
    clip: PaperClipIcon,
    ellipsis: EllipsisHorizontalIcon,
    globe: GlobeAltIcon,
    icon: PhotoIcon,
    paint: PaintBrushIcon,
    rect: RectangleGroupIcon,
    select: CursorArrowRaysIcon,
    shield: ShieldExclamationIcon,
    trash: TrashIcon,
    upload: ArrowUpTrayIcon,
    share: ShareIcon,
    like: HeartIcon,
    stack: Square3Stack3DIcon,
    flip_h: ArrowsRightLeftIcon,
    flip_v: ArrowsUpDownIcon,
    rotate: ArrowPathIcon,
    invert: InvertIcon,
    padding: ArrowsPointingInIcon,
};

const classList = computed(() => ({
    [props.class]: !!props.class,
    'cursor-pointer': props.pointer,
    'text-error': props.danger,
    'text-primary': props.primary,
    'min-w-[16px] w-[16px] h-[16px]': props.sm,
    'w-[24px]': props.lg,
    'w-[20px] h-[20px]': !props.sm && !props.lg,
}));
</script>

<template>
    <component
        v-if="icons[props.type]"
        :is="icons[props.type]"
        :class="classList"
        :title="title"
    />
</template>
