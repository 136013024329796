<script lang="ts" setup>
import {computed, onMounted, ref, toRefs, watch} from 'vue';
import {useRoute} from 'vue-router';
import {loadProject, useSession} from '/src/core/session';
import {toRef} from 'vue';
import {Project, ProjectScreen, getProjectWithScreens} from '/src/api/projects';
import router from '/src/router';
import ModalPricing from './ModalPricing.vue';
import FuiEditor from '../fui/FuiEditor.vue';

const session = useSession();
const {state, setIsPublic} = session;
const {platform, auth, display} = toRefs(state);
const currenProject = ref({} as Project);
const currentScreen = ref({} as ProjectScreen);
const isScreenLoaded = ref(false);
const isSandboxModalOpen = ref(false);
const isPricesVisible = ref(false);
const isScreenNotFound = ref(false);

const route = useRoute();

// computed
const project_id = computed(() => parseInt(route.params.project_id as string));
const screen_id = computed(() => parseInt(route.params.screen_id as string));

watch(
    () => route.params.screen_id,
    async (newScreenId) => {
        isScreenNotFound.value = false;
        isScreenLoaded.value = false;
        await openProject(project_id.value, parseInt(newScreenId as string));
        isScreenLoaded.value = true;
    }
);

const emit = defineEmits(['showModal']);

// methods

async function openProject(project_id, screen_id) {
    if (!project_id) {
        currenProject.value = {
            id: 0,
            title: '',
            screens: [{id: 0}],
            platform: platform.value,
            screen_x: display.value.x,
            screen_y: display.value.y,
            private: true,
        };
        return;
    }

    const projectData = await getProjectWithScreens(project_id);
    if (!projectData || projectData.user_id !== auth.value?.user?.id) {
        if (!auth.value) {
            router.push('/sandbox');
            session.initSandbox();
        } else {
            router.push('/');
        }
        return;
    }
    currenProject.value = projectData;
    let screen;
    if (!screen_id) {
        screen = projectData.screens[0];
        !!screen && router.push(`/editor/${project_id}/${screen.id}`);
    } else {
        screen = projectData.screens?.find((item) => item.id === screen_id);
    }
    if (!screen) {
        isScreenNotFound.value = true;
    }
    currentScreen.value = await loadProject(projectData, screen);
}

function showModalPricing() {
    isPricesVisible.value = true;
}

function closeModalPricing() {
    isPricesVisible.value = false;
}

function update() {
    openProject(project_id.value, screen_id.value);
}

function closeSandboxModal() {
    isSandboxModalOpen.value = false;
    localStorage.setItem('sandboxModalShown', 'true');
}

onMounted(async () => {
    await openProject(project_id.value, screen_id.value);
    if (router.currentRoute.value.path === '/sandbox') {
        session.initSandbox();
    }
    isScreenLoaded.value = true;
    const isSandboxShown = localStorage.getItem('sandboxModalShown');
    isSandboxModalOpen.value = !auth.value && isScreenLoaded.value && !isSandboxShown;
    setIsPublic(false);
});
</script>

<template>
    <div class="hidden lg:block">
        <dialog
            id="sandbox_modal"
            class="modal modal-open"
            v-if="isSandboxModalOpen"
        >
            <div class="modal-box border border-primary text-center -mt-64">
                <button
                    class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                    @click="closeSandboxModal"
                >
                    ✕
                </button>
                <!-- <h3 class="text-xl font-bold">Lopaka.app Editor</h3> -->
                <p class="py-2 text-primary">
                    While in Sandbox everything is stored locally in your browser
                    <span class="font-bold text-success">Sign In to get full access to all features</span>
                </p>
                <div class="text-center pb-4">
                    <router-link
                        to="/login"
                        class="btn btn-success"
                    >
                        Sign In
                    </router-link>
                </div>
                <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/kTxVWBAW4ig?si=IJx4kYZQFi4Opm0W"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>
            </div>
        </dialog>
        <FuiEditor
            :project="currenProject"
            :screen="currentScreen"
            :isScreenLoaded="isScreenLoaded"
            :isScreenNotFound="isScreenNotFound"
            @showModalPricing="showModalPricing"
            @update="update"
        />
        <ModalPricing
            v-if="isPricesVisible"
            @close="closeModalPricing"
            canBeClosed
        />
    </div>
</template>
