<script setup lang="ts">
import {ShallowRef, computed, onBeforeUnmount, onMounted, ref, toRefs, watch} from 'vue';
import {debounce, logEvent} from '/src/utils';
import {saveLayers, useSession} from '/src/core/session';
import FuiButton from '/src/components/fui/FuiButton.vue';
import FuiCanvas from '/src/components/fui/FuiCanvas.vue';
import FuiCode from '/src/components/fui/FuiCode.vue';
import FuiFile from '/src/components/fui/FuiFile.vue';
import FuiIcons from '/src/components/fui/FuiIcons.vue';
import FuiInspector from '/src/components/fui/FuiInspector.vue';
import FuiLayers from '/src/components/fui/FuiLayers.vue';
import FuiSelectScale from '/src/components/fui/FuiSelectScale.vue';
import FuiTabs from '/src/components/fui/FuiTabs.vue';
import FuiTools from '/src/components/fui/FuiTools.vue';
import FuiEditorSettings from '/src/components/fui/FuiCodeSettings.vue';
import {FlipperPlatform} from '/src/platforms/flipper';
import {FlipperRPC} from '/src/flipper-rpc';
import ScreensList from '/src/components/screens/ScreensList.vue';
import {IconLayer} from '/src/core/layers/icon.layer';
import {Point} from '/src/core/point';
import FuiSelectPlatform from '/src/components/fui/FuiSelectPlatform.vue';
import FuiSelectDisplay from '/src/components/fui/FuiSelectDisplay/FuiSelectDisplay.vue';
import {forkProject, Project, ProjectScreen} from '/src/api/projects';
import ProjectTitle from '/src/components/projects/ProjectTitle.vue';
import Icon from '/src/components/layout/Icon.vue';
import router from '/src/router';
import {AdafruitPlatform} from '/src/platforms/adafruit';
import {InkplatePlatform} from '/src/platforms/inkplate';
import {U8g2Platform} from '/src/platforms/u8g2';
import {AdafruitMonochromePlatform} from '/src/platforms/adafruit_mono';

const props = defineProps<{
    project: Project;
    screen: ProjectScreen | undefined;
    readonly?: boolean;
    isScreenNotFound: boolean;
    isScreenLoaded: boolean;
}>();

const emit = defineEmits(['showModalPricing', 'update']);

let fuiImages = {},
    imageDataCache = {};

const fuiCanvas = ref(null),
    activeTab = ref('code'),
    session = useSession();
const {virtualScreen, state} = session;
const {platform, customImages, auth} = toRefs(state);
const {updates} = toRefs(session.virtualScreen.state);
const flipper: ShallowRef<FlipperRPC> = ref(null);
const infoMessage = ref();
const errorMessage = ref();

const isEmpty = computed(() => updates.value && session.state.layers.length === 0);
const isFlipper = computed(() => platform.value === FlipperPlatform.id);
const isSerialSupported = computed(() => window.navigator.serial !== undefined);
const flipperPreviewBtnText = computed(() => (flipper.value ? 'Disconnect' : 'Live View'));
const isPlatformWithParser = computed(() =>
    [
        AdafruitPlatform.id,
        AdafruitMonochromePlatform.id,
        InkplatePlatform.id,
        U8g2Platform.id,
        FlipperPlatform.id,
    ].includes(platform.value)
);

watch(
    updates,
    debounce((newValue, oldValue) => {
        if (oldValue === 1) return; // Ignore initial value
        if (flipper.value) {
            sendFlipperImage();
        }
        saveLayers(props.screen.id);
        updateScreenPreview(props.screen.id);
    }, 1000)
);

onMounted(async () => {
    navigator.serial?.addEventListener('disconnect', flipperDisconnect);
});

onBeforeUnmount(() => {
    navigator.serial?.removeEventListener('disconnect', flipperDisconnect);
});

function setActiveTab(tab) {
    activeTab.value = tab;
}
function prepareImages(e) {
    fuiImages = e;
}

function addImageToCanvas(data) {
    session.state.layers.forEach((layer) => (layer.selected = false));
    const newLayer = new IconLayer(session.getPlatformFeatures());
    newLayer.name = data.name;
    newLayer.selected = true;
    newLayer.modifiers.icon.setValue(data.icon);
    newLayer.size = new Point(data.width, data.height);
    newLayer.updateBounds();
    newLayer.stopEdit();
    session.addLayer(newLayer);
    virtualScreen.redraw();
}

function cleanCustomIcons() {
    for (let key in fuiImages) {
        if (fuiImages[key].isCustom) {
            delete fuiImages[key];
            delete imageDataCache[key];
        }
    }
    customImages.value = [];

    logEvent('button_clear_icons');
}

async function toggleFlipperPreview() {
    if (flipper.value) {
        logEvent('button_flipper', 'disconnect');
        flipperDisconnect();
    } else {
        logEvent('button_flipper', 'connect');
        const flipperRPC = new FlipperRPC();
        const isConnected = await flipperRPC.connect();
        if (isConnected) {
            flipper.value = flipperRPC;
            if (updates.value) {
                sendFlipperImage();
            }
        }
    }
}

function flipperDisconnect() {
    flipper.value.disconnect();
    flipper.value = null;
}

function sendFlipperImage() {
    flipper.value.sendImage(virtualScreen.canvasContext.getImageData(0, 0, 128, 64));
}

function updateScreenPreview(screen_id) {
    props.project.screens = props.project.screens.map((item) => {
        if (item?.id === screen_id) {
            item.img_preview = session.virtualScreen.canvas.toDataURL();
        }
        return item;
    });
}

function copyCode() {
    navigator.clipboard.writeText(session.generateCode().code).then(() => {
        infoMessage.value = 'Copied to clipboard';
    });
    setTimeout(() => {
        infoMessage.value = null;
    }, 2000);
    logEvent('button_copy');
}

function copyPublicLink() {
    const hostname = window.location.origin;
    const link = `${hostname}/gallery/${props.project.id}/${props.screen.id}`;
    navigator.clipboard.writeText(link).then(() => {
        infoMessage.value = 'Link was copied to clipboard';
    });
    setTimeout(() => {
        infoMessage.value = null;
    }, 2000);
    logEvent('button_share');
}

async function cloneProject() {
    if (!auth.value) return;
    logEvent('button_fork');
    const {data, error} = await forkProject(props.project.id);
    if (error) {
        errorMessage.value = error.message;
        setTimeout(() => {
            errorMessage.value = null;
        }, 4000);
    } else {
        const {project_id, screen_id} = data;
        router.push(`/editor/${project_id}/${screen_id}`);
    }
}

function showModalPricing() {
    emit('showModalPricing');
}

function update() {
    emit('update');
}
</script>

<template>
    <div class="toast toast-top toast-center z-50">
        <div
            class="alert alert-warning"
            v-if="errorMessage"
        >
            <span>{{ errorMessage }}</span>
        </div>
        <div
            class="alert alert-success"
            v-if="infoMessage"
        >
            <span>{{ infoMessage }}</span>
        </div>
    </div>
    <div
        class="fui-editor"
        v-if="platform || isScreenNotFound"
    >
        <div class="fui-editor__left">
            <div
                role="tablist"
                class="tabs tabs-bordered"
            >
                <input
                    type="radio"
                    name="editor_tabs_1"
                    role="tab"
                    class="tab font-sans"
                    aria-label="Layers"
                    checked
                />
                <div
                    role="tabpanel"
                    class="tab-content pt-2"
                >
                    <div v-if="!isScreenLoaded && !isScreenNotFound">
                        <div class="skeleton my-1 h-4 ml-2 mr-8"></div>
                        <div class="skeleton my-1 h-4 ml-2 mr-8"></div>
                        <div class="skeleton my-1 h-4 ml-2 mr-8"></div>
                        <div class="skeleton my-1 h-4 ml-2 mr-8"></div>
                        <div class="skeleton my-1 h-4 ml-2 mr-8"></div>
                    </div>
                    <FuiLayers
                        v-else
                        v-show="!isEmpty"
                        :readonly="readonly"
                    ></FuiLayers>
                </div>
                <input
                    type="radio"
                    name="editor_tabs_1"
                    role="tab"
                    class="tab font-sans"
                    aria-label="Screens"
                />
                <div
                    role="tabpanel"
                    class="tab-content pt-2"
                >
                    <ScreensList
                        v-if="readonly || auth || isScreenNotFound"
                        flow="col"
                        @showModalPricing="showModalPricing"
                        :project="project"
                        :platform="platform"
                        :readonly="readonly"
                    />
                    <div
                        v-if="!auth && !readonly"
                        class="text-center py-2"
                    >
                        Please
                        <router-link
                            class="link link-primary"
                            to="/login"
                        >
                            Sign In
                        </router-link>
                        to create Screens and manage Projects
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="pb-1 flex justify-between">
                <ProjectTitle
                    v-if="auth || readonly"
                    full
                    :project="project"
                    :screen="screen"
                    :readonly="readonly"
                    @update="update"
                />
                <div
                    class="flex gap-2"
                    v-if="auth || readonly"
                >
                    <div class="flex gap-2">
                        <FuiButton
                            :title="auth ? `Have a copy of this project` : `Sign in to fork this project`"
                            @click="cloneProject"
                            :disabled="!auth"
                        >
                            <Icon type="fork" />
                            Fork
                        </FuiButton>
                    </div>
                    <div
                        class="flex gap-2"
                        v-if="!project.private && !screen?.private"
                    >
                        <FuiButton
                            :title="`Copy public link`"
                            @click="copyPublicLink"
                        >
                            <Icon type="share" />
                            Share
                        </FuiButton>
                    </div>
                </div>
            </div>
            <div
                class="flex flex-row text-sm mb-2 justify-center"
                v-if="!auth && !readonly"
            >
                <FuiSelectPlatform></FuiSelectPlatform>
                <FuiSelectDisplay></FuiSelectDisplay>
            </div>
            <div
                class="font-sans flex flex-row gap-4 justify-between"
                v-if="isScreenLoaded && !isScreenNotFound"
            >
                <div class="w-1/5">
                    <FuiButton
                        v-if="isFlipper && isSerialSupported"
                        @click="toggleFlipperPreview"
                        title="Preview your design in real-time on your Flipper via USB"
                    >
                        {{ flipperPreviewBtnText }}
                    </FuiButton>
                </div>
                <FuiTools v-if="!readonly"></FuiTools>
                <FuiSelectScale></FuiSelectScale>
            </div>
            <div
                v-if="!isScreenLoaded && !isScreenNotFound"
                class="mx-auto py-48 text-center"
            >
                <span class="loading loading-spinner text-primary loading-lg"></span>
            </div>
            <div
                v-if="isScreenNotFound"
                class="mx-auto py-48 text-center"
            >
                <span class="text-xl">404 Screen Not Found</span>
            </div>
        </div>
        <template v-if="isScreenLoaded && !isScreenNotFound">
            <div class="fui-editor__main">
                <div class="fui-editor__canvas">
                    <FuiCanvas ref="fuiCanvas" />
                </div>
            </div>
            <div class="fui-editor__main-right">
                <FuiInspector :readonly="readonly" />
            </div>
            <div class="fui-editor__bottom">
                <div class="fui-editor__tabs mt-2">
                    <div class="flex justify-between">
                        <div>
                            <FuiTabs
                                v-if="!readonly"
                                :active-tab="activeTab"
                                @set-active-tab="setActiveTab"
                            ></FuiTabs>
                        </div>
                        <FuiButton
                            title="Copy source code"
                            class="-mt-2 mr-1"
                            @click="copyCode"
                        >
                            <Icon
                                type="clipboard"
                                pointer
                            />
                            Copy
                        </FuiButton>
                    </div>
                    <FuiIcons
                        v-show="activeTab === 'images'"
                        :fui-images="fuiImages"
                        :custom-images="customImages"
                        @prepare-images="prepareImages"
                        @icon-clicked="addImageToCanvas"
                        @clean-custom-icons="cleanCustomIcons"
                        ref="fuiIconsList"
                    ></FuiIcons>
                    <FuiCode v-show="activeTab === 'code'"></FuiCode>
                    <div class="buttons-bottom">
                        <div v-if="!readonly">
                            <FuiFile
                                style="margin-right: 8px"
                                type="code"
                                title="import code"
                                @set-active-tab="setActiveTab"
                                v-if="isPlatformWithParser"
                            ></FuiFile>
                            <FuiFile
                                type="image"
                                title="import image"
                                @set-active-tab="setActiveTab"
                            ></FuiFile>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fui-editor__bottom-right">
                <FuiEditorSettings :updates="updates" />
            </div>
        </template>
    </div>
</template>
