<script lang="ts" setup>
import router from '/src/router';
import {computed, ref, toRefs} from 'vue';
import {signOut} from '/src/api/services/supabase';
import {useSession} from '/src/core/session';
import Icon from '/src/components/layout/Icon.vue';
import {getSubscriptionUpdate} from '/src/api/users';

const session = useSession();
const {state} = session;
const {auth, subscription} = toRefs(state);

const isSubscriptionLoading = ref(false);

async function logOut() {
    await signOut();
    session.resetAuth();
    router.push('/');
    window.location.href = '/';
}

const mainLink = computed(() => {
    return auth.value ? '/' : '/sandbox';
});
const userAvatarText = computed(() => {
    return auth.value.user.user_metadata.full_name
        ? auth.value.user.user_metadata.full_name.slice(0, 2)
        : auth.value.user.user_metadata.email.slice(0, 2);
});

const isSubscriptionActive = computed(() => !!subscription.value && subscription.value?.status !== 'expired');

async function manageSubscription() {
    isSubscriptionLoading.value = true;
    const response = await getSubscriptionUpdate(subscription.value.id);
    window.location.href = response.customer_portal;
}
</script>
<template>
    <div class="navbar justify-between pb-0 pt-0 flex-col md:flex-row">
        <div class="navbar-start w-max">
            <router-link
                :to="mainLink"
                class="flex flex-row items-center"
            >
                <div class="w-8">
                    <img
                        class="lopaka-logo"
                        src="/assets/lopaka-logo.svg"
                        width="32"
                        height="32"
                        alt="Lopaka Logo"
                    />
                </div>
                <div class="pl-2 text-lg font-bold text-white">
                    <a
                        href="/"
                        class="text-white"
                    >
                        Lopaka
                    </a>
                </div>
            </router-link>
            <div
                class="pl-4 text-primary hidden lg:block"
                v-if="!auth"
            >
                Graphics editor for u8g2, AdafruitGFX, Arduino, TFT_eSPI, FlipperZero
            </div>
        </div>
        <div class="navbar-center"></div>

        <div class="navbar-end w-max">
            <ul class="menu menu-horizontal flex-row items-center text-base">
                <!-- <li>
                    <a
                        class="link link-primary"
                        href="https://ilin.pt/general/2023/06/07/embedded-screen-graphics-designer.html"
                        target="_blank"
                    >
                        About
                    </a>
                </li> -->
                <li>
                    <router-link
                        class="link link-primary"
                        to="/gallery"
                    >
                        Gallery
                    </router-link>
                </li>
                <li>
                    <router-link
                        class="link link-success"
                        to="/price"
                    >
                        Plans
                    </router-link>
                </li>
                <li>
                    <a
                        class="link link-primary"
                        href="/pages/"
                        target="_blank"
                    >
                        Blog
                    </a>
                </li>
                <li>
                    <a
                        class="link link-primary"
                        href="https://airtable.com/apps27BkejrUF0DWT/shryaJaGovGUA03XA"
                        target="_blank"
                    >
                        Contact Us
                    </a>
                </li>
            </ul>
            <div
                class="tooltip tooltip-bottom"
                data-tip="Source Code"
            >
                <div class="pr-2">
                    <a
                        class="link"
                        href="https://github.com/sbrin/lopaka"
                    >
                        <Icon type="github" />
                    </a>
                </div>
            </div>
            <div
                class="tooltip tooltip-bottom"
                data-tip="Follow Us on Twitter"
            >
                <div class="pl-2 pr-4">
                    <a
                        class="link"
                        href="https://twitter.com/lopaka_app"
                    >
                        <Icon type="twitter" />
                    </a>
                </div>
            </div>
            <div
                v-if="auth"
                class="dropdown dropdown-end dropdown-hover"
            >
                <div
                    tabindex="0"
                    role="button"
                    class="btn btn-circle avatar"
                >
                    <div
                        class="w-8 rounded-full"
                        v-if="auth.user.user_metadata.avatar_url"
                    >
                        <img
                            :src="auth.user.user_metadata.avatar_url"
                            :alt="auth.user.user_metadata.user_name"
                            :title="auth.user.user_metadata.full_name"
                        />
                    </div>
                    <div
                        v-else
                        class="avatar placeholder"
                    >
                        <div class="bg-neutral text-neutral-content w-8 rounded-full">
                            <span class="text uppercase">{{ userAvatarText }}</span>
                        </div>
                    </div>
                </div>
                <ul
                    tabindex="0"
                    class="menu menu-sm bg-neutral rounded-lg z-[1] dropdown-content w-40 mr-2"
                >
                    <li class="menu-title">
                        <div class="truncate max-w-32">
                            {{ auth.user.email }}
                        </div>
                    </li>
                    <li
                        v-if="isSubscriptionActive"
                        @click="manageSubscription"
                    >
                        <a>
                            Subscription...
                            <span
                                class="loading loading-2xs"
                                v-if="isSubscriptionLoading"
                            ></span>
                        </a>
                    </li>
                    <li v-else>
                        <router-link to="/price">
                            Upgrade to
                            <span class="text-success font-bold">Plus</span>
                        </router-link>
                    </li>
                    <li>
                        <a
                            href="#"
                            @click="logOut"
                        >
                            Sign Out
                        </a>
                    </li>
                </ul>
            </div>

            <div v-if="!auth">
                <router-link
                    class="btn btn-outline btn-sm btn-success ml-2"
                    to="/login"
                >
                    Sign In
                </router-link>
            </div>
        </div>
    </div>
</template>
