<script lang="ts" setup>
import {ComputedRef, UnwrapRef, computed, toRefs} from 'vue';
import {AbstractImageLayer} from '../../core/layers/abstract-image.layer';
import {
    AbstractLayer,
    TLayerAction,
    TLayerModifier,
    TLayerModifiers,
    TModifierType,
} from '../../core/layers/abstract.layer';
import {useSession} from '../../core/session';
import {loadFont} from '../../draw/fonts';
import FuiButton from './FuiButton.vue';
import {logEvent} from '../../utils';
import Icon from '/src/components/layout/Icon.vue';
import {PaintLayer} from '/src/core/layers/paint.layer';

const props = defineProps<{
    readonly?: boolean;
}>();

const session = useSession();
const {platform} = toRefs(session.state);
const {updates} = toRefs(session.virtualScreen.state);
const {selectionUpdates} = toRefs(session.editor.state);
let lastUpdate = 0;

const activeLayer: ComputedRef<UnwrapRef<AbstractLayer>> = computed(() => {
    const selection = session.state.layers.filter((l) => l.selected);
    return updates.value && selection.length == 1 ? selection[0] : null;
});

const params: ComputedRef<UnwrapRef<TLayerModifiers>> = computed(() =>
    updates.value && activeLayer.value ? activeLayer.value.modifiers : {}
);

const actions = computed(() => (updates.value && activeLayer.value ? activeLayer.value.actions : []));

const selectedLayers = computed(
    () => session.state.layers.filter((l) => l.selected && l instanceof AbstractLayer).length
);

const layerToMerge = computed(() => selectionUpdates.value && selectedLayers.value > 1);

const fonts = computed(() => {
    return session.platforms[platform.value].getFonts();
});

const palette = computed(() => {
    return session.platforms[platform.value].features.palette;
});

function onChange(event: Event, param: TLayerModifier, value?: any) {
    if (Date.now() - lastUpdate > 500) {
        activeLayer.value.pushHistory();
    }
    lastUpdate = Date.now();
    const target = event.target as HTMLInputElement;
    switch (param.type) {
        case TModifierType.number:
            param.setValue(parseFloat(target.value));
            break;
        case TModifierType.string:
        case TModifierType.color:
            if (props.readonly) return;
            if (value) {
                param.setValue(value);
            } else {
                param.setValue(target.value);
            }
            break;
        case TModifierType.boolean:
            param.setValue(target.checked);
            break;
        case TModifierType.font:
            const font = session.platforms[platform.value]
                .getFonts()
                .find((f: TPlatformFont) => f.name === target.value);
            // lock scrteen while loading font
            session.lock();
            loadFont(font).then(() => {
                session.unlock();
                param.setValue(font.name);
                session.virtualScreen.redraw();
            });
            break;
    }
    session.virtualScreen.redraw();
}

function onAction(action: TLayerAction) {
    action.action();
    session.virtualScreen.redraw();
    logEvent('button_inspector_operations', action.title);
}

function mergeLayers() {
    session.mergeLayers(
        (session.state.layers as AbstractLayer[]).filter(
            (l) => l.selected && (!(l instanceof AbstractImageLayer) || !l.overlay)
        )
    );
    logEvent('button_merge');
}

const LABELS = {
    font: 'Font',
    fontSize: 'Size',
    text: '',
    inverted: 'XOR Draw',
    fill: 'Filled',
    color: 'Color',
    overlay: 'Overlay',
    radius: 'R',
    radiusX: 'RX',
    radiusY: 'RY',
    x: 'X',
    y: 'Y',
    x1: 'X1',
    y1: 'Y1',
    x2: 'X2',
    y2: 'Y2',
    w: 'W',
    h: 'H',
};
</script>
<template>
    <div
        v-if="!readonly && !layerToMerge && !activeLayer"
        class="pt-1"
    >
        <div class="text-lg pb-2">Shortcuts</div>
        <div class="text-xs flex flex-col gap-1 text-gray-300">
            <p>
                <kbd class="kbd kbd-xs bg-base-300">Delete</kbd>
                <kbd class="kbd kbd-xs bg-base-300">Backspace</kbd>
                — remove
            </p>
            <p>
                <kbd class="kbd kbd-xs bg-base-300">←</kbd>
                <kbd class="kbd kbd-xs bg-base-300">→</kbd>
                <kbd class="kbd kbd-xs bg-base-300">↑</kbd>
                <kbd class="kbd kbd-xs bg-base-300">↓</kbd>
                — move 1px
            </p>
            <p>
                <kbd class="kbd kbd-xs bg-base-300">SHIFT</kbd>
                +
                <kbd class="kbd kbd-xs bg-base-300">←</kbd>
                <kbd class="kbd kbd-xs bg-base-300">→</kbd>
                <kbd class="kbd kbd-xs bg-base-300">↑</kbd>
                <kbd class="kbd kbd-xs bg-base-300">↓</kbd>
                — move 10px
            </p>
            <p>
                <kbd class="kbd kbd-xs bg-base-300">CTRL</kbd>
                +
                <kbd class="kbd kbd-xs bg-base-300">↑</kbd>
                <kbd class="kbd kbd-xs bg-base-300">↓</kbd>
                — reorder layers
            </p>
            <p>
                <kbd class="kbd kbd-xs bg-base-300">CTRL</kbd>
                +
                <kbd class="kbd kbd-xs bg-base-300">C</kbd>
                /
                <kbd class="kbd kbd-xs bg-base-300">V</kbd>
                — copy/paste
            </p>
            <p>
                <kbd class="kbd kbd-xs bg-base-300">CTRL</kbd>
                +
                <kbd class="kbd kbd-xs bg-base-300">A</kbd>
                — select all
            </p>
            <p>
                <kbd class="kbd kbd-xs bg-base-300">CTRL</kbd>
                +
                <kbd class="kbd kbd-xs bg-base-300">Z</kbd>
                — undo
            </p>
            <p>
                <kbd class="kbd kbd-xs bg-base-300">CTRL</kbd>
                +
                <kbd class="kbd kbd-xs bg-base-300">+</kbd>
                /
                <kbd class="kbd kbd-xs bg-base-300">-</kbd>
                — zoom in/out
            </p>
            <p>
                <kbd class="kbd kbd-xs bg-base-300">SHIFT</kbd>
                +
                <kbd class="kbd kbd-xs bg-base-300">CLICK</kbd>
                — multi-select
            </p>
            <p>
                <kbd class="kbd kbd-xs bg-base-300">ESC</kbd>
                — reset selection
            </p>
            <div class="text text-sm font-bold">Rectangle</div>
            <p>
                Hold
                <kbd class="kbd kbd-xs bg-base-300">SHIFT</kbd>
                to draw a square
            </p>
            <div class="text text-sm font-bold">Paint</div>
            <p>
                Hold
                <kbd class="kbd kbd-xs bg-base-300">SHIFT</kbd>
                to draw a line
            </p>
            <p>
                <kbd class="kbd kbd-xs bg-base-300">RIGHT CLICK</kbd>
                — eraser
            </p>
        </div>
    </div>
    <div
        class="text-lg pb-2 pt-1"
        v-if="layerToMerge"
    >
        Selected layers ({{ selectedLayers }})
    </div>
    <div
        v-if="
            (activeLayer && activeLayer?.getType() !== 'paint') ||
            (activeLayer?.getType() === 'paint' && (activeLayer as PaintLayer).data)
        "
        class="pt-1"
    >
        <datalist id="presetColors">
            <!-- 16 colors -->
            <option>#000000</option>
            <option>#0000AA</option>
            <option>#00AA00</option>
            <option>#00AAAA</option>
            <option>#AA0000</option>
            <option>#AA00AA</option>
            <option>#AA5500</option>
            <option>#AAAAAA</option>
            <option>#555555</option>
            <option>#5555FF</option>
            <option>#55FF55</option>
            <option>#55FFFF</option>
            <option>#FF5555</option>
            <option>#FF55FF</option>
            <option>#FFFF55</option>
            <option>#FFFFFF</option>
        </datalist>
        <div class="text-lg pb-2 flex flex-row items-center">
            <Icon
                :type="activeLayer.getType()"
                sm
                class="text-gray-500 mr-2"
            ></Icon>
            <div class="truncate">{{ activeLayer.name }}</div>
        </div>
        <div class="flex flex-row flex-wrap gap-2 mb-4">
            <template v-for="(param, name) in params">
                <div
                    class="flex pb-1"
                    v-if="param.type !== TModifierType.image"
                    :class="{
                        'flex-col items-start w-full': ![TModifierType.boolean, TModifierType.number].includes(
                            param.type
                        ),
                        'flex-row items-center w-24': [TModifierType.number].includes(param.type),
                        'flex-row items-center w-full': [TModifierType.boolean].includes(param.type),
                        'w-full': [TModifierType.string].includes(param.type),
                    }"
                >
                    <label
                        class="flex gap-2 text-sm"
                        :class="{
                            'input items-center input-bordered input-sm w-24': param.type == TModifierType.number,
                            'input items-center input-bordered input-sm w-full': param.type == TModifierType.string,
                            'w-full items-center': [TModifierType.boolean, TModifierType.font].includes(param.type),
                            'w-full flex-col': [TModifierType.color].includes(param.type),
                        }"
                    >
                        <template v-if="param.type !== TModifierType.boolean">
                            {{ LABELS[name] ?? name }}
                        </template>
                        <div
                            v-if="param.type == TModifierType.number"
                            class=""
                        >
                            <input
                                :disabled="readonly"
                                class="text-primary w-14"
                                type="number"
                                :value="param.getValue()"
                                @change="onChange($event, param)"
                                :readonly="!param.setValue"
                                :id="`inspector_${param.type}_${name}`"
                            />
                        </div>
                        <input
                            v-else-if="param.type == TModifierType.boolean"
                            :disabled="readonly"
                            class="checkbox checkbox-sm checkbox-primary no-animation"
                            type="checkbox"
                            :checked="param.getValue()"
                            @change="onChange($event, param)"
                            :readonly="!param.setValue"
                            :id="`inspector_${param.type}_${name}`"
                            :key="updates + '_' + name"
                        />
                        <template v-else-if="param.type == TModifierType.string">
                            <input
                                :disabled="readonly"
                                placeholder="Enter text..."
                                class="text-primary"
                                type="text"
                                :value="param.getValue()"
                                @keyup="onChange($event, param)"
                                :readonly="!param.setValue"
                            />
                        </template>
                        <div v-else-if="param.type == TModifierType.color">
                            <div
                                class="color-palette"
                                v-if="palette.length"
                                :key="updates + '_' + name"
                            >
                                <div
                                    class="color-palette-box"
                                    @click="onChange($event, param, color)"
                                    v-for="color in palette"
                                    :style="{backgroundColor: color}"
                                    :class="{selected: color === param.getValue()}"
                                ></div>
                            </div>
                            <input
                                v-else
                                :disabled="readonly"
                                class="text-primary select select-bordered select-sm w-16 pl-0 pr-1"
                                type="color"
                                :value="param.getValue()"
                                @input="onChange($event, param)"
                                :readonly="!param.setValue"
                                list="presetColors"
                                :id="`inspector_${param.type}_${name}`"
                            />
                        </div>
                        <div
                            v-else-if="param.type == TModifierType.font"
                            class="flex w-full"
                        >
                            <select
                                :disabled="readonly"
                                class="select select-sm select-bordered w-full text-primary"
                                :value="param.getValue()"
                                :readonly="!param.setValue"
                                @change="onChange($event, param)"
                            >
                                <option
                                    v-for="font in fonts"
                                    :value="font.name"
                                >
                                    {{ font.title }}
                                </option>
                            </select>
                            <!-- // TODO: upload custom fonts  
                             <FuiButton
                                class="pl-2"
                                variant="square"
                                title="Import custom GFX font"
                            >
                                <Icon
                                    type="upload"
                                    class="text-primary"
                                />
                            </FuiButton> -->
                        </div>
                        <template v-if="param.type === TModifierType.boolean">
                            {{ LABELS[name] ?? name }}
                        </template>
                    </label>
                </div>
            </template>
        </div>
        <template v-if="!readonly">
            <div
                class="text-lg pb-2 w-[240px]"
                v-if="actions.length"
            >
                Image operations
            </div>
            <div class="flex flex-wrap mb-2 gap-2 w-full">
                <div
                    class="font-lg"
                    v-for="action in actions"
                >
                    <FuiButton
                        v-if="action.label !== 'Download'"
                        @click="onAction(action)"
                        :title="action.title"
                        isIcon
                    >
                        <Icon
                            v-if="action.iconType"
                            :type="action.iconType"
                        />
                        <template v-else>
                            {{ action.label }}
                        </template>
                    </FuiButton>
                    <FuiButton
                        v-else
                        @click="onAction(action)"
                        :title="action.title"
                    >
                        <Icon
                            v-if="action.iconType"
                            :type="action.iconType"
                        />
                        <template v-else>
                            {{ action.label }}
                        </template>
                    </FuiButton>
                </div>
            </div>
        </template>
    </div>
    <div
        v-if="(activeLayer && !['icon', 'paint'].includes(activeLayer.getType())) || layerToMerge"
        class="flex flex-row gap-2"
    >
        <FuiButton
            v-if="!readonly"
            @click="mergeLayers"
            title="Merge selected layers into a single bitmap so you can use Paint on it"
        >
            Convert to Bitmap
        </FuiButton>
    </div>
</template>
<style lang="css" scoped>
.inspector-panel {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 8px;
}

.inspector-actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
}

.inspector-panel__param {
    flex: 0 0 calc(50% - 4px);
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    font-size: var(--input-font-size);
}

.inspector-panel__param_row {
    flex-direction: row;
    margin-top: 8px;
    align-items: center;
}

.inspector-panel__param_wide {
    flex-basis: fit-content;
    flex-grow: 1;
}

.inspector__title {
    overflow: hidden;
}

.inspector__input {
    border: none;
    outline: none;
    width: 100%;
}

.inspector__input[type='color'] {
    /* height: 20px; */
    padding: 0px;
    width: 60px;
}

select.inspector__input {
    width: 165px;
}

.color-palette {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 5px;
    background-color: var(--secondary-color);
    padding: 5px;
    width: fit-content;
    border-radius: 6px;
}

.color-palette-box {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 2px solid var(--secondary-color);
    border-radius: 4px;
}

.color-palette-box.selected {
    border-color: var(--primary-color);
}
</style>
