<script lang="ts" setup>
import {UnwrapRef, computed, ref} from 'vue';
import {AbstractLayer} from '../../core/layers/abstract.layer';
import {useSession} from '../../core/session';
import {TextLayer} from '../../core/layers/text.layer';
import {IconLayer} from '../../core/layers/icon.layer';
import VueDraggable from 'vuedraggable';
import Icon from '/src/components/layout/Icon.vue';
import {PaintLayer} from '/src/core/layers/paint.layer';

const props = defineProps<{
    readonly?: boolean;
}>();

const session = useSession();
const drag = ref(false);

const disabled = computed(
    () =>
        session.editor.state.activeLayer?.getType() === 'paint' &&
        !(session.editor.state.activeLayer as PaintLayer).data
);

const layers = computed({
    get: () => session.state.layers.slice().sort((a, b) => b.index - a.index),
    set: (l) => {
        const len = l.length;
        l.forEach((layer, index) => {
            layer.index = len - index;
        });
        session.state.layers = l.slice().sort((a, b) => a.index - b.index);
        session.virtualScreen.redraw();
    },
});

function classNames(layer) {
    return {
        'bg-base-300': layer.selected,
        'text-gray-500': layer.overlay,
    };
}

function setActive(layer: UnwrapRef<AbstractLayer>) {
    layers.value.forEach((l) => (l.selected = false));
    layer.selected = true;
    session.editor.selectionUpdate();
    session.virtualScreen.redraw();
}

function getLayerListItem(layer: UnwrapRef<AbstractLayer>) {
    if (layer instanceof TextLayer) {
        return `${layer.text || 'Empty text'}`;
    } else if (layer instanceof IconLayer) {
        return `${layer.name}`;
    }
    return `${layer.name}`;
}
</script>
<template>
    <ul class="menu menu-xs w-[150px] p-0">
        <VueDraggable
            class="layers__list max-w-full"
            v-model="layers"
            item-key="id"
            @start="drag = true"
            @end="drag = false"
            :disabled="readonly || disabled"
        >
            <template #item="{element}">
                <li
                    class="layer"
                    @click="!disabled && setActive(element)"
                    v-show="element.type !== 'paint' || (element.type === 'paint' && (element as PaintLayer).data)"
                >
                    <a
                        class="flex h-6 max-w-full pl-1 mb-[1px] rounded-none"
                        :class="classNames(element)"
                    >
                        <Icon
                            :type="element.type"
                            sm
                            class="text-gray-500 min-w-4"
                        ></Icon>
                        <div class="truncate grow">
                            <span>{{ getLayerListItem(element) }}</span>
                        </div>
                        <div
                            v-if="!readonly"
                            class="btn text-error btn-xs btn-ghost hidden layer-actions -mr-2"
                            @click.stop="!disabled && session.removeLayer(element as any)"
                        >
                            ×
                        </div>
                    </a>
                </li>
            </template>
        </VueDraggable>
    </ul>
</template>
<style lang="css" scoped>
/*
.layers__list {
  font-size: 24px;
  overflow: hidden;
  margin: 0 16px 16px 0;
  padding: 0 0 8px 0;
  overflow-y: auto;
}
  
.layer {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;
  padding: 2px 0 2px 8px;
  margin-bottom: 1px;
  border-radius: 4px;
  justify-content: space-between;
}

.layer:hover {
  background-color: var(--secondary-color);
}

.layer:hover .layer__remove {
  display: block;
}

.layer__name {
  max-width: 132px;
  overflow: hidden;
  white-space: nowrap;
}





.layer__remove {
  display: none;
  color: var(--danger-color);
  margin: 0 8px;
  height: 32px;
} */

.layer:hover .layer-actions {
    display: flex;
}

.layer_ignored {
    color: #999;
}
</style>
