<script
  lang="ts"
  setup
>
import { nextTick, onMounted, ref, watch } from "vue";
import { ProjectScreen } from "/src/api/projects";

const props = defineProps<{
  screen: ProjectScreen;
  readonly?: boolean;
}>();

const screenInput = ref(null);
const isEditing = ref(false);
const newScreenTitle = ref('');

const emit = defineEmits(["updateScreenTitle"]);

function editScreenName() {
  if (props.readonly) return;
  isEditing.value = true;
  nextTick(() => {
    screenInput.value.focus();
    screenInput.value.select();
  });
}

async function saveScreenName(screen: ProjectScreen) {
  isEditing.value = false;
  if (props.screen.title !== newScreenTitle.value) {
    emit("updateScreenTitle", screen.id, newScreenTitle.value);
    props.screen.title = newScreenTitle.value;
  }
}

onMounted(() => {
  newScreenTitle.value = props.screen.title;
})

defineExpose({
  editScreenName,
});
</script>
<template>
  <div class="h-6">
    <input
      v-show="isEditing"
      ref="screenInput"
      type="text"
      class="input input-xs w-32 h-6"
      v-model="newScreenTitle"
      @blur="saveScreenName(screen)"
      @keydown.esc="isEditing = false"
      @keydown.enter="isEditing = false"
    >
    </input>
    <div
      v-show="!isEditing"
      class="text-sm w-32 h-6 truncate"
      @click="editScreenName"
    >{{ screen.title }}</div>
  </div>
</template>
<style lang="css">
.screen-title-input {
  display: none;
}

.screen-title:hover .screen-title-input {
  display: inline-block;
}

.screen-list-button {
  visibility: hidden;
}

.screen-list-item:hover .screen-list-button {
  visibility: visible;
}

.screen-image {
  image-rendering: pixelated;
  height: 100%;
  width: auto;
}
</style>
