<script lang="ts" setup>
import {computed, onMounted, ref, toRefs, watch} from 'vue';
import {useRoute} from 'vue-router';
import {loadProject, useSession} from '/src/core/session';
import {toRef} from 'vue';
import {Project, ProjectScreen, getProjectWithScreens} from '/src/api/projects';
import router from '/src/router';
import FuiEditor from '../fui/FuiEditor.vue';

const session = useSession();
const {state, setIsPublic} = session;
const {platform, auth, display} = toRefs(state);
const currenProject = ref({} as Project);
const currentScreen = ref({} as ProjectScreen);
const isScreenLoaded = ref(false);
const isSandboxModalOpen = ref(false);
const isScreenNotFound = ref(false);

const route = useRoute();
const project_id = toRef(parseInt(route.params.project_id as string));

// computed
const screen_id = computed(() => parseInt(route.params.screen_id as string));

watch(
    () => route.params.screen_id,
    async (newScreenId) => {
        isScreenNotFound.value = false;
        isScreenLoaded.value = false;
        await openProject(project_id.value, parseInt(newScreenId as string));
        isScreenLoaded.value = true;
    }
);

const emit = defineEmits(['showModal']);

// methods

async function openProject(project_id, screen_id) {
    if (!project_id) {
        currenProject.value = {
            id: 0,
            title: '',
            screens: [{id: 0}],
            platform: platform.value,
            screen_x: display.value.x,
            screen_y: display.value.y,
            private: true,
        };
        return;
    }

    const projectData = await getProjectWithScreens(project_id);
    if (!projectData) {
        router.push(auth.value ? '/' : '/sandbox');
        return;
    }
    currenProject.value = projectData;
    let screen;
    if (!screen_id) {
        screen = projectData.screens[0];
        !!screen && router.push(`/editor/${project_id}/${screen.id}`);
    } else {
        screen = projectData.screens?.find((item) => item.id === screen_id);
    }
    if (!screen) {
        isScreenNotFound.value = true;
    } else {
    }
    currentScreen.value = await loadProject(projectData, screen);
}

onMounted(async () => {
    await openProject(project_id.value, screen_id.value);
    isScreenLoaded.value = true;
    const isSandboxShown = localStorage.getItem('sandboxModalShown');
    isSandboxModalOpen.value = !auth.value && isScreenLoaded.value && !isSandboxShown;
    setIsPublic(true);
});
</script>

<template>
    <FuiEditor
        :project="currenProject"
        :screen="currentScreen"
        :isScreenLoaded="isScreenLoaded"
        :isScreenNotFound="isScreenNotFound"
        readonly
    />
</template>
